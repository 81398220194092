import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import {
    MobileInputWrapper,
    MobileSearchWrapper,
    StyledCloseIcon,
    StyledIcon,
    StyledInputWrapper,
    StyledSearchIcon,
    StyledSearchInput,
} from './header-search.styles';
import { IChildProps } from './header-search.types';

interface ISearchInputLocale {
    placeholder: string;
}

export const MobileSearch: React.VFC<IChildProps> = ({ searchValue, onSubmit, onChange, onClear }) => {
    const inputRef = useRef<HTMLInputElement>();
    const { t } = useTranslation('pages');
    const locale: ISearchInputLocale = { placeholder: t('search.title', { returnObjects: true }) };
    const [opened, setOpened] = useState(false);

    const toggle = useCallback((e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setOpened(s => !s);
    }, []);

    useEffect(() => {
        if (opened) {
            inputRef.current?.focus();
        }
    }, [opened]);

    const onCloseClick = useCallback((e) => {
        onClear();
        toggle(e);
    }, [onClear, toggle]);

    return (
        <MobileSearchWrapper flex='1' justifyContent='flex-end'>
            {opened && (
                <OutsideClickHandler onOutsideClick={toggle}>
                    <MobileInputWrapper>
                        <StyledSearchIcon
                            variant='regular'
                            icon='search'
                            fixed={opened}
                        />
                        <form onSubmit={onSubmit}>
                            <StyledSearchInput
                                ref={inputRef as any}
                                value={searchValue}
                                onChange={onChange}
                                autoFocus={opened}
                                placeholder={opened ? locale.placeholder : ''}
                            />
                        </form>
                        <StyledCloseIcon
                            variant='regular'
                            icon='close'
                            onClick={onCloseClick}
                        />
                    </MobileInputWrapper>
                </OutsideClickHandler>
            )}
            <StyledInputWrapper>
                <StyledIcon>
                    <StyledSearchIcon
                        variant='regular'
                        icon='search'
                        onClick={toggle}
                    />
                </StyledIcon>
            </StyledInputWrapper>
        </MobileSearchWrapper>
    );
};
