import React from 'react';
import { useTranslation } from 'next-i18next';
import {
    DesktopInputWrapper,
    DesktopSearchWrapper,
    StyledSearchIcon,
    StyledSearchInput,
    StyledCloseIcon
} from './header-search.styles';
import { IChildProps } from './header-search.types';

export const DesktopSearch: React.VFC<IChildProps> = ({ onSubmit, searchValue, onChange, onClear }) => {
    const { t } = useTranslation('components');

    return (
        <DesktopSearchWrapper>
            <DesktopInputWrapper>
                <StyledSearchIcon
                    variant='regular'
                    icon='search'
                />
                <form onSubmit={onSubmit}>
                    <StyledSearchInput
                        value={searchValue}
                        onChange={onChange}
                        placeholder={t('search.placeholder')}
                    />

                </form>
                {!!searchValue && (
                    <StyledCloseIcon
                        variant='regular'
                        icon='close'
                        onClick={onClear}
                    />
                )}
            </DesktopInputWrapper>
        </DesktopSearchWrapper>
    );
};
